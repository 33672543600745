import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const SESSION_FOR_LIVE_VIDEO_CARD_FRAGMENT = `
  fragment sessionForLiveVideoCardFragment on Session {
    id
    uid
    name
    featured
    startTime
    endTime
    videoEnabledStartTime
    videoEnabledEndTime
    videoType
    videoEnabled
    videoLiveEnabled
    videoLiveEndpoint
    videoLiveEmbedEndpoint
    videoArchiveEnabled
    videoArchiveEndpoint
    videoArchiveEmbedEndpoint
    bannerFileResource {
      ...fileResourceBaseFragment
    }
    _actions(actions: ["CAN_VIEW_LIVE_VIDEO"]) {
      value
      key
    }
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
